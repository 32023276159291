
import React from 'react';
import './Magicut.css';
import { MdOutlineDoubleArrow } from "react-icons/md";
import { CiSaveDown2 } from "react-icons/ci";

const Magicut = () => {
  return (
   <>
   <div className="container-fluid bg-breadcrumb_magicut">
      <div class="container text-center py-5" style={{maxWidth: "900px" }}>
                {/* <img src="./img/wikuslogo.jpeg" data-wow-delay="0.1s" style={{borderRadius:"20px",height:"100px",width:"150px"}}/> */}
                <ol class="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s"style={{ 
    marginTop: "100px"}}>
                    <li class=""><a href="/"><strong>Home</strong></a><MdOutlineDoubleArrow/></li>
                    {/* <li class="breadcrumb-item"><a href="#">Pages</a></li> */}
                    <li class="breadcrumb-item active text-primary"><strong>Magicut</strong></li>
                </ol>    
            </div>
      </div>

	  <div class="be">
	  <div className="paragraph-container">
  <div style={{display:"flex",justifyContent:"space-between",flexWrap:"wrap"}}>
  <h2 style={{textAlign:"left",color:"#0f253B"}} data-aos="fade-right" ><strong >Magicut</strong></h2>
  <a href="./img/WikusCatalog.pdf" download="wikuscatalog">
  <button className='btn btn-outline-primary btn-page-pulse' ><CiSaveDown2/>  Catalog Download</button>
  </a>
    </div>
    </div>

    <div className='my_chart'>
      <article className="magicut_header_paragraph" >
											<header>
												<h1>Magicut Hole Saws</h1>
											</header>

                  

											<a href="#" class="image left">
                        <img src="./img/productimageS6.png" alt=""/></a>
											<p>
                        Umang Marketing is one of the leading authorized distributors for <strong>Magicut Hole Saws, Bimetal Hole Saws</strong> are toothed HSS strips laser welded
					    to a tough alloy body and disc. These precision manufactured Hole Saws that have outstanding strength, resilience and shatter resistance are manufactured 
						in Bimetal M3 &amp; 8% Cobalt grades.</p>
											<p>Bimetal M3 Hole Saws are manufactured using the superior M3 quality of High Speed Steel that is better than the conventional M2 grade.</p>

                        <div style={{overflowY:"scroll",height:"1100px"}}>
											<h3 >1. Magicut Mini Hole Saws</h3>

										
											<a href="#" class="image left"><img src="./img/magi-11.jpeg" alt=""/></a>

											<p>Magicut Bimetal Mini Hole Saws are toothed HSS strips welded to a tough alloy body and disc. These precision manufactured hole saws have outstanding strength, resilience and shatter resistance.</p>

<strong>Salient Features &amp; Advantages</strong><br/>
• Solid back dis for smooth and precise cut<br/>
• Single piece construction<br/>
• Precise tool geometry<br/>
• More cutting depth compare to conventional holesaws<br/>
• Smooth cutting operation<br/>
• Better rigidity<br/>
• Longer life<br/>


										<p>

</p><table className='table-class' width="100%" border="0px" cellpadding="1px" cellspacing="1px" align="center">
    <tbody><tr bgcolor="#004a62">
	<th width="49%" colspan="2" align="center"><strong><font size="2" color="#ffffff">∅ DIAMETER</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th width="49%" colspan="2" align="center"><strong><font size="2" color="#ffffff">∅ DIAMETER</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">MM</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">INCH</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">MM</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">INCH</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">12</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/2</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">51</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">2</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5/8</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">57</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">2.1/4</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">19</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/4</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">64</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">2.1/2</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">22</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">7/8</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">70</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">2.3/4</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">25</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">76</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">29</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1.1/8</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">83</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3.1/4</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">32</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1.1/4</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">89</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3.1/2</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">35</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1.3/8</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">95</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3.3/4</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">44</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1.3/4</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
    </tr>

</tbody></table>


<p></p>


											<h3>2. Magicut Bimetal Hole Saws</h3>

											<a href="#" class="image left"><img  class="image left" src="./img/magi-22.jpg" alt=""/></a>
											

											<p>Magicut Bimetal Hole Saws are toothed HSS strips electro-beam welded to a tough alloy body and disc. These precision manufactured hole saws that have outstanding strength, resilience and shatter resistance are manufactured in bimetal M3 &amp; 8% cobalt grades.</p>

											<p>Magicut Bimetal M3 Hole Saws are manufactured using the superior M3 quality of high speed steel that is better than the conventional M2 grade. The M3 cutting edge has higher alloying elements that enhances the performance considerably and is useful for cutting aluminum, bakelite, brass, bronze, cast iron, mild steel, plastic, wood etc.</p>

											<p>Magicut Cobalt Hole Saws have 8% cobalt in addition to normal alloying elements that can sustain higher temparatures, has more hardness and greater wear resistance. They are therefore recommended for cutting difficult meterials like cast steels, duralium, hardwork, inconel, inox, non-ferrous alloys, plaster of paris, stainless steel etc.</p>


<strong>Sailent Features &amp; Advantages</strong>



										<p>

</p>
<table
     className='table-class'
      style={{
        width: '100%',
        border: '0px',
        cellpadding: '1px',
        cellspacing: '1px',
        textAlign: 'center',
      }}
    >
      <tbody>
        <tr style={{ backgroundColor: '#004a62' }}>
          <th
            colSpan="2"
            style={{
              width: '100%',
              fontSize: '14px',
              color: '#ffffff',
              textAlign: 'center',
            }}
          >
            <strong>SALIENT FEATURES &amp; ADVANTAGES</strong>
          </th>
        </tr>

        <tr style={{ backgroundColor: '#ECF2F8' }}>
          <th
            colSpan="1"
            style={{
              textAlign: 'left',
              fontSize: '14px',
              color: '#000000',
            }}
          >
            <strong>Solid Back Disc</strong>
          </th>
          <th
            colSpan="1"
            style={{
              textAlign: 'left',
              fontSize: '14px',
              color: '#000000',
            }}
          >
            <strong>• Ensures a smooth and precise cut</strong>
          </th>
        </tr>

        <tr style={{ backgroundColor: '#F9FCFE' }}>
          <th
            colSpan="1"
            style={{
              textAlign: 'left',
              fontSize: '14px',
              color: '#000000',
            }}
          >
            <strong>Variable Pitch 4/6 TPI +ve Rake Teeth</strong>
          </th>
          <th
            colSpan="1"
            style={{
              textAlign: 'left',
              fontSize: '14px',
              color: '#000000',
            }}
          >
            <strong>
              • Reduces cutting vibrations<br />
              • Provides extra swaft clearance while cutting thicker
              sections<br />
              • Scoops out material from the groove more efficiently<br />
              • Useful in cutting tenacious materials like copper and
              stainless steel<br />
              • Easier bite into the object
            </strong>
          </th>
        </tr>

        <tr style={{ backgroundColor: '#ECF2F8' }}>
          <th
            colSpan="1"
            style={{
              textAlign: 'left',
              fontSize: '14px',
              color: '#000000',
            }}
          >
            <strong>Constant Pitch</strong>
          </th>
          <th
            colSpan="1"
            style={{
              textAlign: 'left',
              fontSize: '14px',
              color: '#000000',
            }}
          >
            <strong>
              • Less tendency to snag on contact with irregular surfaces<br />
              • Provides a smoother finish<br />
              • 10 TPI useful for cutting up to 1 mm thick<br />
              • 6 TPI for cutting from 1 mm up to 10 mm thick
            </strong>
          </th>
        </tr>
      </tbody>
    </table>

<p></p>



										<p>

</p><table className='table-class' width="100%" border="0px" cellpadding="1px" cellspacing="1px" align="center">
    <tbody><tr bgcolor="#004a62">
	<th width="24%" colspan="2" align="center"><strong><font size="2" color="#ffffff">∅ DIAMETER</font></strong></th>
	<th width="25%" colspan="2" align="center"><strong><font size="2" color="#ffffff">∅ DIAMETER</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th width="24%" colspan="2" align="center"><strong><font size="2" color="#ffffff">∅ DIAMETER</font></strong></th>
	<th width="25%" colspan="2" align="center"><strong><font size="2" color="#ffffff">∅ DIAMETER</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">MM</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">INCH</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">MM</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">INCH</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">MM</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">INCH</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">MM</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">INCH</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">14</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">9/16;</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">34</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 11/32</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">59</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">2 5/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">102</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">35</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 3/8</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">60</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">2 3/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">105</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4 1/8</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">17</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">11/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">37</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 7/16</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">64</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">2 1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">108</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4 1/4</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">19</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">38</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 1/2</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">65</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">2 9/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">111</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4 3/8</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">20</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">25/32</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">40</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 9/16</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">67</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">2 5/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">114</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4 1/2</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">21</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">13/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">41</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 5/8</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">68</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">2 11/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">121</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4 3/4</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">22</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">7/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">43</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 11/16</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">70</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">2 3/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">127</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">23</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">29/32</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">44</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 3/4</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">73</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">2 7/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">133</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5 1/4</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">24</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">15/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">46</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 13/16</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">76</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">140</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5 1/2</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">24</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">15/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">48</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 7/8</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">79</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3 1/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">146</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5 3/4</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">25</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">50</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 31/32</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">83</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3 1/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">152</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">27</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 1/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">51</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">2</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">86</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3 3/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">160</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6 5/16</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">29</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 1/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">52</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 1/16</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">89</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3 1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">168</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6 5/8</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">30</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 3/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">54</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">2 1/8</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">92</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3 5/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">177</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">7</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">32</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 1/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">56</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">2 3/16</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">95</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3 3/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">200</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">7 7/8</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">33</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 5/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">57</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">2 1/4</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">98</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3 7/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">210</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">8 1/4</font></strong></th>
    </tr>

</tbody></table>


<p></p>



<br/><br/>
                        <hr class="feature-separator ni-margin-3x"/>


											<h2>Hand Hacksaw Blades &amp; Frames</h2>


											

											<h3>1.Hand Hacksaw Blades</h3>

											<a href="#" class="image left"><img  class="image left" src="./img/magic-33.jpg" alt=""/></a>

											<p>Magicut Hand Hacksaw Blades are made from the best quality raw material and using modern state of the art manufacturing process. Magicut hand hacksaw blades offer the best value for money, superior cutting performance long life and work excellent even under tough working conditions.</p>

											<p><strong>Proper Selection of Blade</strong> While selecting a blade "Three Teeth Rule" should be followed and this requires that during cutting a minimum of three teeth be continuously in contact with the work.</p>

											<p><strong>Thin Section</strong> Use a fine tooth blade or else metal wedges up between teeth, stripping them off, if the section is too thin to follow, the "Three Teeth Rule" use a very light steady stroke.</p>

											<p><strong>Thick Section</strong> Use a coarse tooth blade with heavy pressure. This gives greater pressure per tooth and consequently a deeper cut. The larger space between teeth gives ample chip clearance.</p>

											<p><strong>Hand Section</strong> Special care must be taken when cutting harder materials. It is better in terms of blade life to use finer tooth blades blades, with a light pressure and slower speed of cutting or else heat is built up rapidly on the cutting edge causing the metal to soften even in the case of high speed steel blades. Faster speeds are permissible if a coolant is used.</p>


											<h3>2. Magicut Bimetal High Speed Hand Hacksaw Blade</h3>


											<a href="#" class="image img"><img class="image left" src="./img/magi-44.jpeg" alt="" height={"150px"}/></a>


											<p>A virtually unbreakable bimetal blad for superb cutting performance, wear resistance and safety. It combines the toothed high speed steel strip, electron-beam welded to a spring steel back, resulting into a highly flexible blade.</p>

											<p>Processing extreme cutting performance that is vastly superior to all hard high speed blades or conventional flexible blades. They cut through all types of materials including alloys and stainless steels.</p>



											<h3>3. Magicut Bimetal 8% Cobalt High Speed Steel Hand Hacksaw Blade</h3>


											<a href="#" class="image img"><img  class="image left" src="./img/productimage5S17.jpg" alt="" width={'320px'}/></a>


											<p>A shatter proof blade for high wear resistance as compared to conventional bimetal blades. This has all attributes of bimetal with high alloy 8% cobalt tooth.</p>

											<p>The tooth have hardness of 66 to 68 HRC with high wear resistance property. They cut through all types of materials including high alloys steels.</p>




											<h3>4. Magicut All Hard High Speed Steel Hand Hacksaw Blade</h3>


											<a href="#" class="image img"><img class="image left" src="./img/productimage5S18.jpg" alt="" width={'320px'}/></a>


											<p>A rigid all hard blade for highly accurate cutting for long life that is ideal for general-purpose work around the workshop. These blades are uniformly hardened throughout and have a fully hardened back and cutting edge.</p>



											<h3>5. Magicut Carbon Steel Hand Hacksaw Blade</h3>


											<a href="#" class="image img"><img class="image left" src="./img/productimage5S19.jpg" alt="" width={'320px'}/></a>


											<p>High carbon steel blades for all light duty work and are unbreakable in normal condition. They are suitable for all relatively soft materials, such as aluminium copper, brass, mild steel and other soft metals.</p>




										<p>

</p>
<table className='table-class' width="100%" border="0px" cellpadding="1px" cellspacing="1px" align="center">
    <tbody>
        <tr bgcolor="#004a62">
	<th width="25%" colspan="1" align="center"><strong><font size="2" color="#ffffff">SIZE IN INCEHES</font></strong></th>
	<th width="25%" colspan="1" align="center"><strong><font size="2" color="#ffffff">SIZE IN MM</font></strong></th>
	<th width="24%" colspan="1" align="center"><strong><font size="2" color="#ffffff">TPI</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">10 x 1/2 x 0.025 (23G)</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">250 x 12.50 x 0.63</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">14, 18, 24, 32</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">12 x 1/2 x 0.025 (23G)</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">300 x 12.50 x 0.63</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">14, 18, 24, 32</font></strong></th>
    </tr>

</tbody></table>


<p></p>



<br/><br/>
                        <hr class="feature-separator ni-margin-3x"/>


											<h2>High Speed Steel Tool Bits &amp; Cut-Off Blades</h2>
									

											<a href="#" class="image left"><img src="./img/productimageS60.png" alt=""/></a>
											
											<h3>1. Magicut HSS M-2 Tool Bits</h3>

											<p>Made from High Speed Steel bearing tungston-modybdenum grade for use on relatively soft material.
											</p>

											<h3>2. Magicut HSS M-35 Tool Bits</h3>

											<p>Made from High Speed Steel bearing 5% Cobalt. It is an economical grade for general purpose use in workshop and is best suited for applications involving interrupted cuts and roughing operation.
											</p>
											
											<h3>3. Magicut HSS M-42 Tool Bits</h3>

											<p>Have a high degree of hot hardness and wear-resistance. Made from High Speed Steel bearing 8% Cobalt, these offer top efficiency on difficult-to-machine alloys and high strength steels.
											</p>
										
											<h3>4. Magicut HSS T-42 Tool Bits</h3>

											<p>Have exceptional wear resistance property. Made of 10% Cobalt bearing High Speed Steel, these have high hot hardness, edge-holding ability in addition to ample toughness for all types of cutting tool applications including those at high temperatures and speeds. They are especially adopted for roughing or finishing operations where maximum tool life is required; for large-batch automatic lathe work and all types of lathe and boring operations.
											</p>
										
											<h3>5. Relative Properties of Magicut Grades</h3>
											<a href="#" class="image left"><img src="./img/productimage4S1.jpg" alt=""/></a>

											<p>Depending on the nature of cutting operation like ease of cutting, hardness of job, machinebility of the job, speed of cutting and heat generated at the cutting action the right grade of tool is to be selected. The relative hot hardness, wear resistance and toughness of various grades are indicated in the chart to serve as a general guide.
											</p>


											<h3>6. Square &amp; Round Tool Bits </h3>
											<a href="#" class="image img"><img src="./img/productimage5S20.jpg" alt=""/></a>

											{/* <a href="#" class="image left"><i>Square & Round Tool Bits</i><img src="./img/productimage1H2.jpg" alt="" /></a> */}

										<p>

</p><table className='table-class' width="100%" border="0px" cellpadding="1px" cellspacing="1px" align="center">

    <tbody><tr bgcolor="#ffffff">
	<th width="49%" colspan="11" align="center"><strong><font size="2" color="#ffffff"><a href="#" class="image center"><img src="./img/productimage1H1.jpg" alt=""/></a></font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th width="49%" colspan="11" align="center"><strong><font size="2" color="#ffffff"><a href="#" class="image center"><img src="./img/productimage1H2.jpg" alt=""/></a></font></strong></th>
    </tr>

    <tr bgcolor="#004a62">
	<th width="49%" colspan="11" align="center"><strong><font size="2" color="#ffffff">SIZE IN INCHES</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th width="49%" colspan="11" align="center"><strong><font size="2" color="#ffffff">CORRESPONDING SIZE IN MM</font></strong></th>
    </tr>


    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">W/d</font></strong></th>
	<th colspan="10" align="center"><strong><font size="2" color="#000000">L</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">W/d</font></strong></th>
	<th colspan="10" align="center"><strong><font size="2" color="#000000">L</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">2 ½</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">3</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">65</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">75</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">100</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">2 ½</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">65</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">75</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">100</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">2 ½</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">65</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">75</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">100</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">200</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">2 ½</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">65</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">75</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">100</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">200</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">10</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">75</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">100</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">200</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">7/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3 ½</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">10</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">11</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">90</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">100</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">200</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">250</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">10</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">12</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">75</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">100</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">250</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4 ½</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">115</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">200</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">20</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">100</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">125</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">200</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">7/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">22</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">200</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">7</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">10</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">25</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">175</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">200</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">250</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1.1/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">10</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">30</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">200</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">250</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1.1/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">10</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">35</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">200</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">250</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1.1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">10</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">40</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">200</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">250</font></strong></th>
    </tr>

</tbody></table>


<p></p>

											<h3>7. Rectangular / Flat Tool Bits</h3>
											{/* <a href="#" class="image left"><img src="./img/productimage1H4S1.jpg" alt="" /></a> */}


										<p>

</p><table className='table-class' width="100%" border="0px" cellpadding="1px" cellspacing="1px" align="center">

    <tbody><tr bgcolor="#ffffff">
	<th width="100%" colspan="15" align="center"><strong><font size="2" color="#ffffff"><a href="#" class="image center"><img src="./img/productimage1H4S1.jpg" alt=""/></a></font></strong></th>
    </tr>

    <tr bgcolor="#004a62">
	<th width="49%" colspan="7" align="center"><strong><font size="2" color="#ffffff">SIZE IN INCHES</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th width="49%" colspan="7" align="center"><strong><font size="2" color="#ffffff">CORRESPONDING SIZE IN MM</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">W</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">H</font></strong></th>
	<th colspan="5" align="center"><strong><font size="2" color="#000000">L</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">W</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">H</font></strong></th>
	<th colspan="5" align="center"><strong><font size="2" color="#000000">L</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">3</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">12</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">100</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">10</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">100</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">12</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">75</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">100</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">20</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">100</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">125</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">25</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">10</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">100</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">11</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">100</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">7/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">11</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">75</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">12</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">100</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">20</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">100</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">25</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">10</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">12</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">75</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">100</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">10</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">100</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">125</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">10</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">20</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">100</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">125</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">10</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">25</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">12</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">20</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">100</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">125</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">7</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">12</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">25</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">175</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 1/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">7</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">12</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">32</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">175</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">7</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">12</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">38</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">175</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">20</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">125</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">7/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">22</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">7</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">25</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">100</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">175</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 1/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">7</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">32</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">100</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">175</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">38</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">7</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">20</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">25</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">175</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">7</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">20</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">38</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">175</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 1/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">7</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">25</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">32</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">175</font></strong></th>
    </tr>

</tbody></table>
											<h3>8. Single &amp; Double Bevelled Tapered Ground Cut-Off Blades</h3>
											<a href="#" class="image left"><i>Rectangular / Flat Tool Bits</i><img src="./img/productimage2S2.jpg" alt="" /></a>


										<p>

</p><table className='table-class' width="100%" border="0px" cellpadding="1px" cellspacing="1px" align="center">
    <tbody><tr bgcolor="#ffffff">
	<th width="49%" colspan="7" align="center"><strong><font size="2" color="#ffffff"><a href="#" class="image center"><img src="./img/productimage1H3.jpg" alt=""/></a></font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th width="49%" colspan="7" align="center"><strong><font size="2" color="#ffffff"><a href="#" class="image center"><img src="./img/productimage1H4.jpg" alt=""/></a></font></strong></th>
    </tr>

    <tr bgcolor="#004a62">
	<th width="49%" colspan="7" align="center"><strong><font size="2" color="#ffffff">SIZE IN INCHES</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th width="49%" colspan="7" align="center"><strong><font size="2" color="#ffffff">CORRESPONDING SIZE IN MM</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">W</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">H</font></strong></th>
	<th colspan="5" align="center"><strong><font size="2" color="#000000">L</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">W</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">H</font></strong></th>
	<th colspan="5" align="center"><strong><font size="2" color="#000000">L</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4 ½</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">1.50</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">12</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">115</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 1/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">1.50</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">18</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">125</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/32</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4 ½</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">2.50</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">12</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">115</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/32</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">2.50</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">125</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4 ½</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">3</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">12</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">115</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">3</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">20</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">125</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">7/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">3</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">22</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">20</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6 ½</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">25</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">165</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 1/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">7</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">28</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">175</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 1/18</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">7</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">28</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">175</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 1/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">7</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">32</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">175</font></strong></th>
    </tr>

</tbody></table>

<h3>9. P-Type / T-Shaped Cut-Off Blades</h3>
										<a href="#" class="image left"><i>Rectangular / Flat Tool Bits</i><img src="./img/productimage2S2.jpg" alt="" /></a>


										<p>

</p><table className='table-class' width="100%" border="0px" cellpadding="1px" cellspacing="1px" align="center">

    <tbody><tr bgcolor="#ffffff">
	<th width="100%" colspan="15" align="center"><strong><font size="2" color="#ffffff"><a href="#" class="image center"><img src="./img/productimage1H4S2.jpg" alt=""/></a></font></strong></th>
    </tr>

    <tr bgcolor="#004a62">
	<th width="49%" colspan="7" align="center"><strong><font size="2" color="#ffffff">SIZE IN INCHES</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th width="49%" colspan="7" align="center"><strong><font size="2" color="#ffffff">CORRESPONDING SIZE IN MM</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">Ref.No.</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">W</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">H</font></strong></th>
	<th colspan="4" align="center"><strong><font size="2" color="#000000">L</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">Ref.No.</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">W</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">H</font></strong></th>
	<th colspan="4" align="center"><strong><font size="2" color="#000000">L</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">P1N</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">0.40</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3 1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">P1N</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">90</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">P1</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4 1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">P1</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1.50</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">12</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">115</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">P2N</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5/64</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4 1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">P2N</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">12</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">115</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">P2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/32</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4 1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">P2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">2.50</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">12</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">115</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">P3S</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4 1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">P3S</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">12</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">115</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">P3N</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/32</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 1/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">P3N</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">2.50</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">18</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">125</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">P3W</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 1/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">P3W</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">18</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">125</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">P4W</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5/32</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 1/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">P4W</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">18</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">125</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">P52</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1 1/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">P52</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">18</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">125</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">P5X</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">P5X</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">20</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">125</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">P5N</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5/32</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">P5N</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">20</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">125</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">P5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">P5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">20</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">125</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">P8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">7/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">P8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">22</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">P8N</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5/32</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">7/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">P8N</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">22</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">P8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">7/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">P8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">22</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">P9</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">7/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">P9</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">22</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">-</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
    </tr>

</tbody></table>


<p></p>
					<h3>10. Parting Tools</h3>

				<a href="#" class="image left"><i>Parting Tools</i><img src="./img/productimage2S2.jpg" alt="" /></a>

<table className='table-class' width="100%" border="0px" cellpadding="1px" cellspacing="1px" align="center">

    <tbody><tr bgcolor="#ffffff">
	<th width="100%" colspan="15" align="center"><strong><font size="2" color="#ffffff"><a href="#" class="image center"><img src="./img/productimage1H4S3.jpg" alt=""/></a></font></strong></th>
    </tr>

    <tr bgcolor="#004a62">
	<th width="49%" colspan="3" align="center"><strong><font size="2" color="#ffffff">SIZE IN INCHES</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th width="49%" colspan="3" align="center"><strong><font size="2" color="#ffffff">CORRESPONDING SIZE IN MM</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">W</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">H</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">L</font></strong></th>
	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">W</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">H</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">L</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/32</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/2</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">2.50</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">12</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">100</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/32</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">2.50</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">125</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/4</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">3</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">20</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">7/8</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">3</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">22</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3/16</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">6</font></strong></th>

	<th bgcolor="#FFFFFF" width="2%" colspan="1" align="center"><strong><font size="2" color="#000000"></font></strong></th>

	<th colspan="1" align="center"><strong><font size="2" color="#000000">5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">25</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">150</font></strong></th>
    </tr>

</tbody></table>
       </div>                
</article>
    </div>
	</div>
  

   
   
   
   </>
  );
};

export default Magicut;