import React from 'react'
import './Footer.css';

const Footer = () => {
  return (
    <div>
      <div className="container-fluid footer bg-dark py-5 wow fadeIn" data-wow-delay="0.2s" style={{backgroundColor:"#0f253b "}}>
        <div className="container py-5">

          <div className="row g-5">
          
          <div className="col-md-6 col-lg-6 col-xl-3">
              <div className="footer-item d-flex flex-column">
                <div>
            <a href='/'> <img src="img/422.png" alt="" style={{marginBottom:"-10px",height:"110px"}}/> </a>
                
                    </div>
                <p className="mb-3" style={{ color: "white",fontSize:"12px" }}>
                  Our aim is to excel in the areas we are specialized by continuous interaction with our existing and new customers.
                </p>
              </div>
            </div>


            <div className="col-md-6 col-lg-6 col-xl-3">
              <div className=" d-flex flex-column" style={{fontSize:"12px"}}>
                <h4 className="text-white mb-4">Contact Info</h4>
                <p style={{ color: "white",fontSize:"12px" }}><a href="#" style={{ color: "white",fontSize:"12px",textDecoration:"none" }}><i className="fa fa-map-marker-alt text-primary me-2"></i> SM-118, 1st Floor, ITI Industrial Estate, White Field, Mahadevapura Post, Bengaluru-560 048 Karnataka state (India).</a></p>
                <p style={{ color: "white",fontSize:"12px" }}><a href="mailto:umangmrkt@gmail.com" style={{ color: "white",fontSize:"12px",textDecoration:"none" }}><i className="fas fa-envelope text-primary me-2"></i> umangmrkt@gmail.com</a></p>
                <p style={{ color: "white",fontSize:"12px" }}><a href="tel:+91 8041145681" style={{ color: "white",fontSize:"12px",textDecoration:"none" }}><i className="fas fa-phone text-primary me-2"></i> +91 9606645009, +91 8041145681</a></p>
              </div>
            </div>

            <div className="col-md-6 col-lg-6 col-xl-3">
              <div className="d-flex flex-column" style={{fontSize:"12px"}}>
                <h4 className="text-white mb-4">Quick Links</h4>
                <p style={{ color: "white",fontSize:"12px",textDecoration:"none" }}><a href="/" style={{ color: "white",fontSize:"12px",textDecoration:"none" }}> Home</a></p>
                <p style={{ color: "white",fontSize:"12px",textDecoration:"none",marginTop:"-7px" }}><a href="/about" style={{ color: "white",fontSize:"12px",textDecoration:"none" }}> About</a></p>
                <p style={{ color: "white",fontSize:"12px",textDecoration:"none",marginTop:"-7px" }}><a href="/service" style={{ color: "white",fontSize:"12px",textDecoration:"none" }}> Service</a></p>
                <p style={{ color: "white",fontSize:"12px",textDecoration:"none",marginTop:"-7px" }}><a href="/project" style={{ color: "white",fontSize:"12px",textDecoration:"none" }}> Testimonial</a></p>
                <p style={{ color: "white",fontSize:"12px",textDecoration:"none",marginTop:"-7px" }}><a href="/contact" style={{ color: "white",fontSize:"12px",textDecoration:"none" }}> Contact Us</a></p>
              </div>
            </div>

            {/* Google Map */}
            <div className="col-md-6 col-lg-6 col-xl-3 resposnive_footer">
              <div>
                <iframe 
                  className="w-100" 
                  style={{ height: "200px" }} 
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3887.610893037899!2d77.698168!3d12.996721!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae110a45705743%3A0x72f48a0a09b1291a!2sUmang%20Marketing!5e0!3m2!1sen!2sus!4v1724760858457!5m2!1sen!2sus" 
                  loading="lazy" 
                  referrerpolicy="no-referrer-when-downgrade">
                </iframe>
              </div>
            </div>

            
          </div>
        </div>
      </div>

      <div className="container-fluid copyright py-4">
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-md-6 text-center text-md-start mb-md-0">
              <span className="text-body" style={{fontSize:"12px"}}>
                <a href="#" className="border-bottom text-white" style={{textDecoration:"none"}}>
                  <i className="fas fa-copyright text-light me-2"></i>2024 Umang Marketing
                </a>, All Rights Reserved.
              </span>
            </div>
            <div className="col-md-6 text-center text-md-end text-body" style={{fontSize:"12px"}}>
              Designed By <a className="border-bottom text-white" href="https://excerptech.com" target='_blank' rel="noopener noreferrer">Excerpt Technologies Pvt Ltd</a>
            </div>
          </div>
        </div>
      </div>

      <a href="#" className="btn btn-primary btn-lg-square back-to-top"><i className="fa fa-arrow-up"></i></a>
    </div>
  )
}

export default Footer


















