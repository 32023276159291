import React from 'react'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import GavelIcon from '@mui/icons-material/Gavel';
import FactoryIcon from '@mui/icons-material/Factory';
import ElectricRickshawOutlinedIcon from '@mui/icons-material/ElectricRickshawOutlined';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import ScienceIcon from '@mui/icons-material/Science';
import SecurityIcon from '@mui/icons-material/Security';
import { GiSteelClaws } from "react-icons/gi";

const OurPartners = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4, // Adjust based on how many slides you want to show
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 768, // Mobile view adjustments
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 480, // Smaller mobile view adjustments
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      };
  return (
    <div className='chart-container' style={{marginTop:'-100px'}}>
        <div className="h-100">
      <h4 className="text-primary" style={{textAlign:'center'}}>Our Partners</h4>
      <h1 className="display-4 mb-4" style={{color:"#0f253B"}}>Empowering Growth Through Partnerships With Following the Industries</h1>

      <header className="header-btClear-large-our-clients">
        <div className="dash cl1"></div>

        <Slider {...settings} >
          
        <div style={{display: "flex", flexDirection: "row", alignItems: "center",margin:"10px"}} className='chart-container12'>
  
    <div style={{display: "flex", alignItems: "center"}} >
      {/* Increase the icon size */}
      <PrecisionManufacturingIcon style={{fontSize: "80px", marginRight: "10px", color:"#f68a0a"}} />
      
      {/* Adjust text styles */}
      <h5 style={{fontSize: "20px", fontWeight: "bold", margin: 0, color: "#333"}}>
        Mechanical Industries
      </h5>
    </div>
 
</div>

<div style={{display: "flex", flexDirection: "row", alignItems: "center",margin:"10px"}} className='chart-container12'>
         
            <div style={{display: "flex", alignItems: "center"}}>
      {/* Increase the icon size */}
      <ConnectingAirportsIcon style={{fontSize: "80px", marginRight: "10px", color:"#f68a0a"}} />
      
      {/* Adjust text styles */}
      <h5 style={{fontSize: "20px", fontWeight: "bold", margin: 0, color: "#333"}}>
       AeroSpace  Industries
      </h5>
    </div>
         
          </div>

          
<div style={{display: "flex", flexDirection: "row", alignItems: "center",margin:"10px"}} className='chart-container12'>
            
            <div style={{display: "flex", alignItems: "center"}}>
      {/* Increase the icon size */}
      <GavelIcon style={{fontSize: "80px", marginRight: "10px", color:"#f68a0a"}} />
      
      {/* Adjust text styles */}
      <h5 style={{fontSize: "20px", fontWeight: "bold", margin: 0, color: "#333"}}>
       Forging <br/> Industries
      </h5>
    </div>
           
          </div>

          <div style={{display: "flex", flexDirection: "row", alignItems: "center",margin:"10px"}} className='chart-container12' >
          
            <div style={{display: "flex", alignItems: "center"}}>
      {/* Increase the icon size */}
      <FactoryIcon style={{fontSize: "80px", marginRight: "10px", color:"#f68a0a"}} />
      
      {/* Adjust text styles */}
      <h5 style={{fontSize: "20px", fontWeight: "bold", margin: 0, color: "#333"}}>
       Aluminium  Industries
      </h5>
    </div>
           
          </div>

          <div style={{display: "flex", flexDirection: "row", alignItems: "center",margin:"10px"}} className='chart-container12'>
           
            <div style={{display: "flex", alignItems: "center"}}>
      {/* Increase the icon size */}
      < ElectricRickshawOutlinedIcon
 style={{fontSize: "80px", marginRight: "10px", color:"#f68a0a"}} />
      
      {/* Adjust text styles */}
      <h5 style={{fontSize: "20px", fontWeight: "bold", margin: 0, color: "#333"}}>
       Automobile  Industries
      </h5>
    </div>
           
          </div>
         
         
          <div style={{display: "flex", flexDirection: "row", alignItems: "center",margin:"10px"}} className='chart-container12'>
            
            <div style={{display: "flex", alignItems: "center"}}>
      {/* Increase the icon size */}
      < MedicalServicesIcon
 style={{fontSize: "80px", marginRight: "10px", color:"#f68a0a"}} />
      
      {/* Adjust text styles */}
      <h5 style={{fontSize: "20px", fontWeight: "bold", margin: 0, color: "#333"}}>
       Medical Equipements
      </h5>
    </div>
           
          </div>

          <div style={{display: "flex", flexDirection: "row", alignItems: "center",margin:"10px"}} className='chart-container12'>
          
            <div style={{display: "flex", alignItems: "center"}}>
      {/* Increase the icon size */}
      < ScienceIcon
 style={{fontSize: "80px", marginRight: "10px", color:"#f68a0a"}} />
      
      {/* Adjust text styles */}
      <h5 style={{fontSize: "20px", fontWeight: "bold", margin: 0, color: "#333"}}>
      Chemical Engineering
      </h5>
    </div>
            
          </div>

          <div style={{display: "flex", flexDirection: "row", alignItems: "center",margin:"10px"}} className='chart-container12'>
            
            <div style={{display: "flex", alignItems: "center"}}>
      {/* Increase the icon size */}
      < GiSteelClaws
 style={{fontSize: "80px", marginRight: "10px", color:"#f68a0a"}} />
      
      {/* Adjust text styles */}
      <h5 style={{fontSize: "20px", fontWeight: "bold", margin: 0, color: "#333"}}>
       Steel <br/>Industries
      </h5>
    </div>
           
          </div>

          <div style={{display: "flex", flexDirection: "row", alignItems: "center",margin:"10px"}} className='chart-container12'>
          
            <div style={{display: "flex", alignItems: "center"}}>
      {/* Increase the icon size */}
      < SecurityIcon
 style={{fontSize: "80px", marginRight: "10px", color:"#f68a0a"}} />
      
      {/* Adjust text styles */}
      <h5 style={{fontSize: "20px", fontWeight: "bold", margin: 0, color: "#333"}}>
       Defense<br/> Industries
      </h5>
    </div>
           
          </div>
          
        </Slider>
      </header>
    </div>
      
    </div>
  )
}

export default OurPartners